<template>
  <v-container fluid class="registro-container pa-0">
    <v-row no-gutters style="min-height: 100vh">
      <v-col cols="12" md="10" lg="8" xl="6" class="mx-auto">
        <v-card class="registro-card mx-auto my-4">
          <v-card-title class="text-center d-block pa-6">
            <v-btn icon class="float-left" @click="$router.push('/')">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h1 class="text-h4 font-weight-bold primary--text">
              Registro de Usuario
            </h1>
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="step" vertical>
              <!-- Paso 1: Datos Personales -->
              <v-stepper-step
                :complete="step > 1"
                step="1"
                :rules="[() => validStep1]"
              >
                Datos Personales
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-form ref="form1" v-model="validStep1">
                  <!-- CUIT y Tipo -->
                  <v-row dense>
                    <v-col cols="12" md="5">
                      <small>
                        (Si lo desconoce, lo puede consultar
                        <a
                          href="http://servicioswww.anses.gob.ar/ConstanciadeCuil2/Inicio.aspx"
                          target="_blank"
                        >
                          AQUÍ </a
                        >)
                      </small>
                      <v-text-field
                        v-model="cuit"
                        label="CUIT"
                        v-mask="'##-########-#'"
                        :rules="[
                          (v) => !!v || '',
                          (v) => cuitverificado || 'Cuit inválido',
                        ]"
                        @change="verificarCuit"
                        ref="refcuit"
                        required
                        outlined
                        dense
                        class="mt-2"
                      />
                    </v-col>
                    <v-col cols="12" md="7" class="pl-5">
                      <div class="text-subtitle-2">TIPO DE CUIT:</div>
                      <v-radio-group
                        v-model="tipocuit"
                        row
                        :rules="[(v) => !!v || '']"
                        required
                        class="mt-2"
                      >
                        <v-radio label="Femenino" value="F"></v-radio>
                        <v-radio label="Masculino" value="M"></v-radio>
                        <v-radio label="Empresa" value="E"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <!-- Nombre -->
                  <v-text-field
                    v-model="nombrecompleto"
                    label="APELLIDO Y NOMBRE"
                    :rules="[(v) => !!v || '']"
                    required
                    outlined
                    dense
                  ></v-text-field>

                  <!-- Teléfono -->
                  <v-row dense>
                    <v-col cols="4">
                      <v-text-field
                        v-model="prefijotel"
                        label="PREFIJO"
                        :rules="[(v) => !!v || '']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="numerotel"
                        label="NÚMERO"
                        :rules="[(v) => !!v || '']"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- Celular -->
                  <v-row dense>
                    <v-col cols="4">
                      <v-text-field
                        v-model="prefijocel"
                        label="PREFIJO CELULAR"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="numerocel"
                        label="NÚMERO CELULAR"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="empresacel"
                        :items="empresascel"
                        label="EMPRESA"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <!-- Email -->
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="mail"
                        label="MAIL"
                        :rules="[
                          (v) => !!v || '',
                          (v) =>
                            !v ||
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) ||
                            'Mail incorrecto',
                        ]"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="confirmarmail"
                        label="CONFIRMAR MAIL"
                        :rules="[
                          (v) => !!v || '',
                          (v) =>
                            !v ||
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) ||
                            'Mail incorrecto',
                          (v) => v === mail || 'Los mails no coinciden',
                        ]"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-end mt-4">
                    <v-btn
                      color="primary"
                      @click="step = 2"
                      :disabled="!validStep1"
                    >
                      Continuar
                      <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </v-form>
              </v-stepper-content>

              <!-- Paso 2: Contraseña -->
              <v-stepper-step
                :complete="step > 2"
                step="2"
                :rules="[() => validStep2]"
              >
                Contraseña
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-form ref="form2" v-model="validStep2">
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="contrasenia"
                        label="CONTRASEÑA"
                        :rules="[(v) => !!v || '']"
                        required
                        outlined
                        dense
                        :append-icon="vercontra ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="vercontra = !vercontra"
                        :type="vercontra ? 'text' : 'password'"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="confirmarcontrasenia"
                        label="CONFIRMAR CONTRASEÑA"
                        :rules="[
                          (v) => !!v || '',
                          (v) =>
                            v === contrasenia || 'Las contraseñas no coinciden',
                        ]"
                        required
                        outlined
                        dense
                        :append-icon="vercontra2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="vercontra2 = !vercontra2"
                        :type="vercontra2 ? 'text' : 'password'"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-space-between mt-4">
                    <v-btn text @click="step = 1">
                      <v-icon left>mdi-arrow-left</v-icon>
                      Volver
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="GuardarRegistro"
                      :loading="loading"
                      :disabled="!validStep2"
                    >
                      Registrar
                    </v-btn>
                  </div>
                </v-form>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.registro-container {
  background: linear-gradient(to right, #e0f7fa, #4f798f);
  min-height: 100vh;
}

.registro-card {
  max-width: 800px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

/* Mejoras para campos en móvil */
:deep(.v-input__slot) {
  min-height: 44px !important;
}

:deep(.v-text-field__details) {
  padding-left: 12px;
}

:deep(.v-messages__message) {
  line-height: 1.2;
}

/* Ajustes responsive */
@media (max-width: 960px) {
  .registro-container {
    background: #ffffff;
  }

  .registro-card {
    margin: 0;
    box-shadow: none;
  }

  .v-card-title {
    font-size: 1.5rem !important;
  }

  .v-row {
    margin: 0 -8px;
  }
}

@media (max-width: 600px) {
  .registro-container {
    padding: 0;
    background: #ffffff;
  }

  .registro-card {
    margin: 0;
    border-radius: 0;
    min-height: 100vh;
    width: 100%;
    box-shadow: none;
  }

  .v-stepper__content {
    padding: 12px 16px;
  }

  .v-card-title {
    padding: 16px !important;
    font-size: 1.25rem !important;
  }

  /* Ajustar campos en móvil */
  .v-col {
    padding: 4px 8px !important;
  }

  /* Botones más grandes en móvil */
  .v-btn {
    height: 44px !important;
    min-width: 120px !important;
  }
}

/* Ajustes para pantallas muy pequeñas */
@media (max-width: 360px) {
  .v-card-title {
    font-size: 1.1rem !important;
  }

  .v-stepper__step__step {
    margin-right: 8px;
  }

  .v-stepper__content {
    padding: 8px 12px;
  }
}
</style>

<script>
export default {
  name: "Formulario_registro",

  data: () => ({
    step: 1,
    validStep1: false,
    validStep2: false,
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
    cuitverificado: true,
    tipocuit: null,
    cuit: "",
    nombrecompleto: "",
    prefijotel: "",
    numerotel: "",
    prefijocel: "",
    numerocel: "",
    mail: "",
    confirmarmail: "",
    contrasenia: "",
    confirmarcontrasenia: "",
    empresacel: "",
    empresascel: ["Tuenti", "Movistar", "Claro", "Personal", "Nextel"],
    vercontra: false,
    vercontra2: false,
  }),

  methods: {
    verificarCuit() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}users/controlCuit/${this.cuit}`)
        .then(function (response) {
          console.log("Verificar cuit", response);
          if (response.data.usuario) {
            obj.$swal.fire({
              title: "¡Atención!",
              text: "Este cuit ya esta registrado en el sistema.",
              icon: "info",
            });
          }
          obj.cuitverificado = response.data.cuitValido;
          obj.$refs.refcuit.validate();
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },

    async GuardarRegistro() {
      if (!this.$refs.form2.validate()) return;

      this.loading = true;
      try {
        await this.$ajax.post(`${this.$apitramites}auth/register`, {
          cuit: Number(this.cuit.split("-").join("")),
          nombre: this.nombrecompleto,
          tipo_cuit: this.tipocuit,
          telefono_area: this.prefijotel,
          telefono: this.numerotel,
          celular_area: this.prefijocel,
          celular: this.numerocel,
          mail: this.confirmarmail,
          clave: this.confirmarcontrasenia,
          celular_empresa: this.empresacel,
        });

        this.$swal.fire({
          title: "¡Exito!",
          text: "Se ha enviado un correo de verificación a su email",
          icon: "success",
        });

        this.$router.push("/");
      } catch (error) {
        let mensaje = "Error, intente nuevamente";
        switch (error.response.data.message) {
          case "CUIT_INVALID":
            mensaje = "Cuit inválido";
            break;
          case "USER_EXIST":
            mensaje = "Este usuario ya esta registrado en el sistema";
            break;
          default:
            mensaje = "Error al enviar mail";
            break;
        }
        this.$swal.fire({
          title: "Error",
          text: mensaje,
          icon: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
