<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :permanent="!isMobile"
      :temporary="isMobile"
      :expand-on-hover="!isMobile"
      app
      dark
      class="menu-drawer"
      width="256"
    >
      <!-- Perfil -->
      <v-list dense>
        <v-list-item class="profile-section">
          <v-list-item-avatar color="darken-1">
            <v-icon large>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">{{
              NombreUsuario
            }}</v-list-item-title>
          </v-list-item-content>
          <v-btn v-if="isMobile" icon @click="drawer = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- Menú -->
      <v-list nav dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.to"
          link
          class="menu-item"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list>
          <v-list-item @click="DialogContra = true" class="menu-action">
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cambiar Contraseña</v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="DialogDatosPersonales = true"
            class="menu-action"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Editar Datos</v-list-item-title>
          </v-list-item>

          <v-list-item @click="CerrarSesion" class="menu-action">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <!-- Diálogo Cambiar Contraseña -->
    <v-dialog
      v-model="DialogContra"
      persistent
      max-width="400"
      style="z-index: 9999"
    >
      <v-card>
        <v-form ref="formcontra" v-model="validformcontra" lazy-validation>
          <v-card-title class="font-weight-bold">
            <v-row justify="center"> Cambiar Contraseña </v-row>
          </v-card-title>
          <v-card-text>
            <br />
            <v-text-field
              label="Contraseña Anterior"
              v-model="clave_vieja"
              id="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :rules="[(v) => !!v || '']"
              required
              filled
              dense
              outlined
            ></v-text-field>
            <v-text-field
              label="Contraseña Nueva"
              v-model="contrasenia"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show2 = !show2"
              :type="show2 ? 'text' : 'password'"
              :rules="[(v) => !!v || '']"
              required
              dense
              filled
              outlined
            ></v-text-field>
            <v-text-field
              label="Confirmar Contraseña"
              v-model="clave_nueva"
              id="password2"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show3 = !show3"
              :type="show3 ? 'text' : 'password'"
              :rules="[
                (v) => !!v || '',
                (v) => v == this.contrasenia || 'Las contraseñas no coinciden',
              ]"
              required
              dense
              filled
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              dark
              color="error"
              class="mr-2"
              @click="DialogContra = false"
            >
              cancelar
            </v-btn>
            <v-btn color="claro" dark @click="GuardarContra()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Diálogo Editar Datos -->
    <v-dialog
      v-model="DialogDatosPersonales"
      persistent
      max-width="400"
      style="z-index: 9999"
    >
      <v-card>
        <v-form ref="formdatos" v-model="validformdatos" lazy-validation>
          <v-card-title class="font-weight-bold">
            <v-row justify="center"> Datos Personales </v-row>
          </v-card-title>
          <v-card-text>
            <br />
            <p style="margin-top: -10px" class="text-subtitle-2">
              Apellido y Nombre:
            </p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.nombre"
                :rules="[(v) => !!v || '']"
                filled
                required
                dense
                outlined
              ></v-text-field>
            </div>
            <p style="margin-top: -10px" class="text-subtitle-2">Teléfono:</p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.telefono_area"
                :rules="[(v) => !!v || '']"
                required
                filled
                prefix="0"
                class="mr-2"
                style="width: 90px"
                dense
                outlined
              ></v-text-field>
              <v-text-field
                v-model="datospersonales.telefono"
                :rules="[(v) => !!v || '']"
                required
                dense
                filled
                outlined
              ></v-text-field>
            </div>
            <p style="margin-top: -10px" class="text-subtitle-2">Mail:</p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.mail"
                :rules="[(v) => !!v || '']"
                required
                dense
                filled
                outlined
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              dark
              color="error"
              class="mr-2"
              @click="DialogDatosPersonales = false"
            >
              cancelar
            </v-btn>
            <v-btn color="claro" dark @click="GuardarDatosPersonales()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.menu-drawer {
  background: linear-gradient(
    135deg,
    var(--v-primary-base),
    var(--v-secondary-base)
  );
  border-right: none;
  height: 100% !important;
  max-height: 100vh !important;
  overflow-y: auto;
}

.profile-section {
  padding: 16px 8px;
}

/* Aumentar tamaño del nombre de usuario */
.profile-section :deep(.text-h6) {
  font-size: 1.1rem !important;
  font-weight: 500;
}

/* Ajustar tamaño de los items del menú */
.menu-item {
  /* margin: 10px 0px; */
  border-radius: 8px;
  transition: all 0.3s;
  /* height: 48px !important; */
}

.menu-item :deep(.v-list-item__title) {
  font-size: 0.95rem !important;
  font-weight: 400;
}

/* Ajustar tamaño de los iconos */
.menu-item :deep(.v-icon) {
  font-size: 22px;
}

/* Ajustar acciones del menú */
.menu-action {
  opacity: 0.8;
  transition: all 0.3s;
  height: 48px !important;
}

.menu-action :deep(.v-list-item__title) {
  font-size: 0.95rem !important;
}

.menu-action:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 600px) {
  .menu-drawer {
    width: 256px !important;
  }

  /* Aumentar aún más el tamaño en móviles */
  .menu-item :deep(.v-list-item__title),
  .menu-action :deep(.v-list-item__title) {
    font-size: 1rem !important;
  }

  .menu-item,
  .menu-action {
    height: 52px !important;
  }
}
</style>

<script>
export default {
  data: () => ({
    menuItems: [
      {
        title: "Inicio",
        icon: "mdi-home",
        to: "/principal",
      },
      {
        title: "Inmuebles",
        icon: "mdi-home-city",
        to: "/Inmueble",
      },
      {
        title: "Comercios",
        icon: "mdi-store",
        to: "/Comercio",
      },
      {
        title: "Vehiculos",
        icon: "mdi-car",
        to: "/Vehiculos",
      },
      {
        title: "Cementerio",
        icon: "mdi-bank",
        to: "/Cementerio",
      },
      {
        title: "Escribanos",
        icon: "mdi-scale-balance",
        to: "/Escribanos",
      },
      {
        title: "Obras Privadas",
        icon: "mdi-domain",
        to: "/ObrasPrivadas",
      },
      // {
      //   title: "Multas",
      //   icon: "mdi-traffic-light",
      //   to: "/Multas",
      // },
    ],
    drawer: true,
    miniVariant: true,
    isMobile: false,
    DialogContra: false,
    DialogDatosPersonales: false,
    contraActual: "",
    contraNueva: "",
    contraConfirm: "",
    datosPersonales: {
      nombre: "",
      email: "",
    },
    show1: false,
    show2: false,
    show3: false,
    validformcontra: true,
    validformdatos: true,
    clave_vieja: "",
    clave_nueva: "",
    contrasenia: "",
    datospersonales: {},
  }),
  computed: {
    NombreUsuario() {
      // Obtener los datos del usuario almacenados en el almacenamiento local
      var userData = JSON.parse(localStorage.getItem("userData"));
      return userData.nombre;
    },
  },
  mounted() {
    this.checkScreen();
    window.addEventListener("resize", this.checkScreen);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreen);
  },
  created() {
    this.datospersonales = JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    CerrarSesion() {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      this.$router.push("/");
    },
    checkScreen() {
      this.isMobile = window.innerWidth < 960;
      if (!this.isMobile) {
        this.drawer = true;
        this.miniVariant = true;
      } else {
        this.miniVariant = false;
      }
    },
    async cambiarContrasena() {
      if (this.$refs.formContra.validate()) {
        try {
          // Aquí va tu lógica para cambiar la contraseña
          // Por ejemplo:
          await this.$ajax.post(
            `${this.$apitramites}usuario/cambiarContrasena`,
            {
              contraActual: this.contraActual,
              contraNueva: this.contraNueva,
            }
          );

          this.DialogContra = false;
          // Mostrar mensaje de éxito
        } catch (error) {
          // Manejar error
          console.error(error);
        }
      }
    },
    async guardarDatos() {
      if (this.$refs.formDatos.validate()) {
        try {
          // Aquí va tu lógica para guardar los datos
          // Por ejemplo:
          await this.$ajax.post(
            `${this.$apitramites}usuario/actualizarDatos`,
            this.datosPersonales
          );

          this.DialogDatosPersonales = false;
          // Mostrar mensaje de éxito
        } catch (error) {
          // Manejar error
          console.error(error);
        }
      }
    },
    GuardarContra() {
      if (this.contrasenia == this.clave_nueva) {
        var obj = this;
        this.$ajax
          .patch(`${this.$apitramites}users/changePassword/`, {
            cuit: Number(this.datospersonales.cuit),
            clavevieja: this.clave_vieja,
            clavenueva: this.clave_nueva,
          })
          .then(function (response) {
            console.log("guardar contra", response);
            obj.$swal.fire({
              title: "¡Contraseña modificada con exito!",
              text: "Vuelve a iniciar sesión",
              icon: "success",
            });
            obj.$refs.formcontra.reset();
            obj.DialogContra = false;
            obj.CerrarSesion();
          })
          .catch(function (error) {
            obj.$swal.fire({
              title: "Error",
              text: error ? "Datos Incorrectos" : "",
              icon: "error",
            });
          });
      } else {
        obj.$swal.fire({
          title: "Error",
          text: "Las contraseñas no coinciden",
          icon: "error",
        });
      }
    },
    GuardarDatosPersonales() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}users/guardarDatosPersonales/`, {
          nombre: this.datospersonales.nombre,
          telefono_area: this.datospersonales.telefono_area,
          telefono: this.datospersonales.telefono,
          celular_area: this.datospersonales.celular_area,
          celular: this.datospersonales.celular,
          mail: this.datospersonales.mail,
        })
        .then(function (response) {
          console.log("guardar datos personales", response);
          obj.$swal.fire({
            title: "¡Editado!",
            text: "Vuelve a iniciar sesión para ver los cambios",
            icon: "success",
          });
          obj.$refs.formdatos.reset();
          obj.DialogDatosPersonales = false;
          obj.CerrarSesion();
        })
        .catch(function (error) {
          obj.$swal.fire({
            title: "Error",
            text: error,
            icon: "error",
          });
        });
    },
    expandirMenu() {
      this.navegacion_menu = true;
    },
    contraerMenu() {
      this.navegacion_menu = false;
    },
    toggleNavigationMenu() {
      this.navegacion_menu = !this.navegacion_menu;
    },
  },
  watch: {
    isMobile(newVal) {
      if (!newVal) {
        this.drawer = true;
        this.miniVariant = true;
      } else {
        this.miniVariant = false;
      }
    },
  },
};
</script>
