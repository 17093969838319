<template>
  <div class="principal-container">
    <!-- Estadísticas -->
    <v-row>
      <v-col cols="12" lg="6">
        <h2 class="text-h4 font-weight-bold mb-6">Detalles de la cuenta</h2>

        <v-row>
          <v-col
            v-for="estadistica in estadisticas"
            :key="estadistica.tabla"
            cols="12"
            sm="6"
            md="6"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 4 : 2"
                :class="{ 'on-hover': hover }"
                :to="AbrirDesdeInicio(estadistica.tabla)"
                class="stat-card"
              >
                <v-card-text>
                  <div class="d-flex flex-column">
                    <div class="text-subtitle-1 text-capitalize mb-2">
                      {{ estadistica.tabla }}
                    </div>
                    <div class="d-flex justify-space-between align-center">
                      <span class="text-h4 font-weight-bold">{{
                        estadistica.cantidad
                      }}</span>
                      <v-icon large>{{ estadistica.icono }}</v-icon>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>

      <!-- Agenda -->
      <v-col cols="12" lg="6">
        <h2 class="text-h4 font-weight-bold mb-6">Agenda</h2>

        <v-card v-if="!agendas.length" class="empty-state pa-4 text-center">
          <v-icon size="64" color="grey lighten-1">mdi-calendar-blank</v-icon>
          <div class="text-h6 grey--text mt-2">No hay eventos programados</div>
        </v-card>

        <template v-else>
          <v-card
            v-for="agenda in agendas"
            :key="agenda.id"
            class="mb-4 agenda-card"
            outlined
          >
            <v-row no-gutters>
              <v-col cols="auto" class="pa-4">
                <div class="date-badge primary">
                  <span class="text-caption white--text">
                    {{
                      new Date(agenda.fecha)
                        .toLocaleString("es-ES", { month: "short" })
                        .toUpperCase()
                    }}
                  </span>
                  <span class="text-h4 white--text font-weight-bold">
                    {{ new Date(agenda.fecha).getDate() }}
                  </span>
                </div>
              </v-col>

              <v-col class="pa-4">
                <h3 class="text-h6 font-weight-bold mb-2">
                  {{ agenda.titulo }}
                </h3>
                <p class="mb-2">{{ agenda.descripcion }}</p>
                <v-btn
                  v-if="agenda.link"
                  :href="agenda.link"
                  target="_blank"
                  text
                  color="primary"
                  class="px-0"
                >
                  <v-icon left>mdi-open-in-new</v-icon>
                  Visitar sitio
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Principal",
  data: () => ({
    estadisticas: [],
    agendas: [],
  }),
  mounted() {
    console.log("Componente Principal montado");
    this.TraerEstadisticas();
  },
  methods: {
    TraerEstadisticas() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}users/traerEstadisticas`)
        .then(function (response) {
          console.log("traer estadisticas", response);
          obj.estadisticas = response.data;
          obj.TraerAgendas();
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    TraerAgendas() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/Agenda`)
        .then(function (response) {
          console.log("traer agendas", response);
          obj.agendas = response.data;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    AbrirDesdeInicio(sistema) {
      switch (sistema) {
        case "cementerio":
          return "/cementerio";
        case "comercios":
          return "/comercio";
        case "vehiculos":
          return "/vehiculos";
        case "inmuebles":
          return "/inmueble";
        case "escribanos":
          return "/escribanos";
        default:
          return "/obrasprivadas";
      }
    },
  },
};
</script>

<style scoped>
.stat-card {
  transition: all 0.3s ease;
  cursor: pointer;
}

.stat-card.on-hover {
  transform: translateY(-4px);
}

.date-badge {
  min-width: 80px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agenda-card {
  transition: all 0.3s ease;
}

.empty-state {
  border: 2px dashed rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
