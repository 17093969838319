import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4f798f',
        secondary: '#162F3D',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#f5f7fa',
        surface: '#ffffff',
        claro: '#4f798f',
        oscuro: '#162F3D',
        inmuebles: '#E53935',
        comercios: '#00ACC1',
        vehiculos: '#8E24AA',
        cementerio: '#43A047',
        escribanos: '#FB8C00',
        obrasprivadas: '#5C6BC0'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});

