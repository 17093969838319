<template>
  <v-container fluid class="login-container pa-0">
    <v-row no-gutters style="min-height: 100vh">
      <!-- Columna izquierda -->
      <v-col cols="12" md="8" class="hero-section d-none d-md-flex">
        <div class="hero-overlay"></div>
        <div class="hero-content">
          <h1 class="text-h2 font-weight-bold white--text mb-6">
            Bienvenido al Portal de Trámites de La Costa
          </h1>
          <p class="text-h5 white--text font-weight-light">
            Gestione sus trámites de manera rápida y segura desde cualquier
            lugar
          </p>
          <div class="mt-12 d-flex align-center">
            <v-icon color="white" class="mr-4">mdi-shield-check</v-icon>
            <span class="white--text">Acceso seguro</span>
            <v-divider vertical class="mx-6" dark></v-divider>
            <v-icon color="white" class="mr-4">mdi-clock-fast</v-icon>
            <span class="white--text">Trámites rápidos</span>
          </div>
        </div>
      </v-col>

      <!-- Columna derecha -->
      <v-col cols="12" md="4" class="login-form-section">
        <div class="login-form-container">
          <v-img
            src="@/assets/fondos/LOGO_LA_COSTA_BLANCO.png"
            max-height="100"
            contain
            class="mb-6"
          ></v-img>

          <h2 class="text-h4 white--text mb-8 text-center font-weight-medium">
            Portal de Trámites
          </h2>

          <v-form @submit.prevent="EntrarLogin" class="login-form">
            <v-text-field
              v-model="usuariologin"
              label="CUIT"
              v-mask="'##-########-#'"
              dense
              outlined
              dark
              class="custom-field mb-2"
              prepend-inner-icon="mdi-account"
              :rules="[(v) => !!v || 'Ingrese su CUIT']"
            ></v-text-field>

            <v-text-field
              v-model="contrasenialogin"
              label="CONTRASEÑA"
              :type="vercontra ? 'text' : 'password'"
              dense
              outlined
              dark
              class="custom-field"
              prepend-inner-icon="mdi-lock"
              :append-icon="vercontra ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="vercontra = !vercontra"
              @keyup.enter="EntrarLogin"
              :rules="[(v) => !!v || 'Ingrese su contraseña']"
            ></v-text-field>

            <div class="text-right mb-6">
              <v-btn
                text
                small
                dark
                @click="dialogOlvideContra = true"
                class="text-caption font-weight-medium px-0"
              >
                ¿Olvidaste tu contraseña?
              </v-btn>
            </div>

            <v-btn
              block
              color="primary"
              height="48"
              class="mb-4 login-btn"
              elevation="2"
              @click="EntrarLogin"
              :loading="loading"
            >
              Ingresar
            </v-btn>

            <v-btn
              block
              outlined
              dark
              height="48"
              class="register-btn"
              @click="FormularioRegistro"
            >
              Crear cuenta nueva
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>

    <!-- Dialog mejorado -->
    <v-dialog v-model="dialogOlvideContra" max-width="400" persistent>
      <v-card class="dialog-card">
        <v-card-title class="text-h5 pb-2">Recuperar Contraseña</v-card-title>
        <v-card-text class="pt-4">
          <p class="text-subtitle-2 mb-4">
            Ingrese su CUIT para recuperar su contraseña
          </p>
          <v-text-field
            v-model="cuitolvidecontra"
            label="CUIT"
            v-mask="'##-########-#'"
            outlined
            dense
            class="custom-field"
            :rules="[(v) => !!v || 'Ingrese su CUIT']"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pb-4 px-6">
          <v-spacer></v-spacer>
          <v-btn text @click="dialogOlvideContra = false" class="mr-2">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="OlvideContra" elevation="2">
            Recuperar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      fondo: "",

      usuariologin: "",
      contrasenialogin: "",

      dialogOlvideContra: false,
      validformregistro: true,

      snackbarconfirm: false,
      timeout: 3000,
      msj: "",

      cuitolvidecontra: "",
      cuitverificado: true,
      tipocuit: null,
      cuit: "",
      nombrecompleto: "",
      prefijotel: "",
      numerotel: "",
      prefijocel: "",
      numerocel: "",
      mail: "",
      confirmarmail: "",
      contrasenia: "",
      confirmarcontrasenia: "",
      empresacel: "",
      empresascel: ["Tuenti", "Movistar", "Claro", "Personal", "Nextel"],
      vercontra: false,
      datosusuario: {},
    };
  },
  methods: {
    FormularioRegistro() {
      this.$router.push({ path: "/formulario_registro" });
    },

    EntrarLogin() {
      var cuit = Number(this.usuariologin.split("-").join(""));
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}auth/login`, {
          cuit: cuit,
          clave: this.contrasenialogin,
        })
        .then(function (response) {
          if (response.data) {
            // obj.$swal.fire({
            // title: "¡Logueado con exito!",
            // text: "Inmueble eliminado con éxito",
            //   icon: "success"
            // });
            console.log("Login usuario", response);
            obj.datosusuario = response.data.user[0];
            console.log("datos usuario", obj.datosusuario);
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data.user[0])
            );
            localStorage.setItem("cuit", obj.usuariologin);
            localStorage.setItem("token", response.data.token);
            obj.$ajax.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.token;
            obj.$router.push({
              path: "/principal",
              name: "Principal",
              params: { datosusuario: obj.datosusuario },
            });
          } else {
            console.log("Login usuario2", response);
            obj.$swal.fire({
              title: "Error",
              text: response.data.respuesta,
              icon: "error",
            });
          }
        })
        .catch(function (error) {
          console.log("login error", error);
          // alert("Usuario o contraseña incorrecta")
          obj.$swal.fire({
            icon: "error",
            title: "Usuario o contraseña incorrecta",

            // title: "Are you sure?",
            // text: "You won't be able to revert this!",
            // icon: "warning",
            // showCancelButton: true,
            // confirmButtonText: "Yes, delete it!",
            // cancelButtonText: "No, cancel!",
            // reverseButtons: true
          });
        });
    },
    OlvideContra() {
      var obj = this;
      this.$ajax
        .patch(
          `${this.$apitramites}users/forgotPassword/${Number(
            this.cuitolvidecontra.split("-").join("")
          )}`
        )
        .then(function (response) {
          console.log("olvide contra", response);
          obj.$swal.fire({
            title: "¡Exito!",
            text: response.data.msj,
            icon: "success",
          });
          obj.dialogOlvideContra = false;
          obj.cuitolvidecontra = "";
        })
        .catch(function (error) {
          console.log(error);
          obj.$swal.fire({
            title: "Error",
            text: "Error al enviar mail, intente nuevamente.",
            icon: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.login-container {
  background: url("../assets/fondos/fondo_login.jpeg") center/cover no-repeat;
  position: relative;
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 80px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(22, 47, 61, 0.9),
    rgba(79, 121, 143, 0.8)
  );
}

.hero-content {
  position: relative;
  max-width: 700px;
  z-index: 1;
  padding-left: 80px;
}

.login-form-section {
  background: rgba(22, 47, 61, 0.95);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 48px 40px;
}

.login-form {
  width: 100%;
}

/* Campos personalizados */
:deep(.custom-field) {
  border-radius: 8px;
}

:deep(.custom-field .v-input__slot) {
  min-height: 48px !important;
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  transition: all 0.3s ease;
}

:deep(.custom-field:hover .v-input__slot) {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

:deep(.custom-field.v-input--is-focused .v-input__slot) {
  border-color: var(--v-primary-base) !important;
}

/* Botones personalizados */
.login-btn {
  text-transform: none;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  font-weight: 500;
}

.register-btn {
  text-transform: none;
  letter-spacing: 0.5px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.register-btn:hover {
  border-color: rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.05);
}

/* Dialog personalizado */
.dialog-card {
  border-radius: 12px;
  padding: 16px;
}

/* Responsive */
@media (max-width: 960px) {
  .login-form-container {
    padding: 32px 24px;
  }

  .hero-content {
    padding: 40px 20px;
  }
}

@media (max-width: 600px) {
  .login-form-container {
    padding: 24px 20px;
  }

  .login-form-section {
    min-height: 100vh;
  }

  h2.text-h4 {
    font-size: 1.75rem !important;
  }
}
</style>
