<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    class="zindex-dialog"
  >
    <v-card>
      <v-app-bar color="oscuro" fixed dark>
        <v-spacer></v-spacer>
        <v-toolbar-title class="mayuscula"
          >Pagar deudas de
          {{ user.datoscementerio.descripcion }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar>
      <v-card-text class="margenappbar">
        <v-data-table
          v-model="cuotasseleccionadas"
          :headers="headerscuotas"
          :items="cuotas"
          item-key="cunica"
          show-select
          class="custom-header-style"
          :items-per-page="-1"
          group-by="anio"
          ref="tablacuotas"
          hide-default-footer
          disable-sort
        >
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              :ripple="false"
              @click="clickaso(item)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th :colspan="2">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].anio }}
            </th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right"></th>
            <th class="text-right">${{ SumaPorAnio(items, "original") }}</th>
            <th class="text-right">${{ SumaPorAnio(items, "actualizado") }}</th>
            <th class="text-right">${{ SumaPorAnio(items, "total") }}</th>
          </template>
          <template v-slot:[`item.estadodeuda`]="{ item }">{{
            Estado(item)
          }}</template>
          <template v-slot:[`item.fecha_vencimiento`]="{ item }">{{
            item.fecha_ven1 | moment("DD/MM/YYYY")
          }}</template>
          <template v-slot:[`item.original_lindo`]="{ item }"
            >${{ formatNumber(item.importe) }}</template
          >
          <template v-slot:[`item.descuento_lindo`]="{ item }">{{
            formatNumber(item.descuento)
          }}</template>
          <template v-slot:[`item.recargos_lindo`]="{ item }"
            >${{ formatNumber(item.recargo) }}</template
          >
          <template v-slot:[`item.total_lindo`]="{ item }">
            ${{ formatNumber(item.totalcuota) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="custom-actions">
        <div style="position: absolute; left: 50%; transform: translateX(-50%)">
          <v-btn dark color="error" class="mr-2" @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold"
            color="claro"
            @click="AbrirCuotas()"
          >
            Siguiente
          </v-btn>
        </div>

        <div class="footertotal" style="margin-left: auto">
          <h2 class="mr-2">Total</h2>
          <v-card dark flat outlined height="30" width="130" class="mr-2">
            <h3 style="margin-top: 2px; text-align: right" class="mr-2">
              ${{ SumaCuotasSeleccionadas("totalcuota") }}
            </h3>
          </v-card>
        </div>
      </v-card-actions>

      <v-dialog
        v-model="DialogPagarDeudas"
        width="1200"
        max-height=""
        persistent
      >
        <v-card>
          <v-card-title
            class="headline"
            style="background-color: #4f798f; color: white"
          >
            CUOTAS SELECCIONADAS
          </v-card-title>
          <v-card-text style="padding: 0px">
            <v-data-table
              :headers="headerscuotasapagar"
              :items="cuotasapagar"
              :items-per-page="4"
              item-key="cunica"
              locale="es"
            >
              <template v-slot:[`item.fecha_vencimiento`]="{ item }">{{
                item.fecha_ven1 | moment("DD/MM/YYYY")
              }}</template>
              <template v-slot:[`item.original_lindo`]="{ item }"
                >${{ formatNumber(item.importe) }}</template
              >
              <template v-slot:[`item.descuento_lindo`]="{ item }"
                >${{ formatNumber(item.descuento) }}</template
              >
              <template v-slot:[`item.recargos_lindo`]="{ item }"
                >${{ formatNumber(item.recargo) }}</template
              >
              <template v-slot:[`item.total_lindo`]="{ item }">
                <b>${{ formatNumber(item.totalcuota) }}</b>
              </template>
              <!-- <template v-slot:footer>
              <v-footer style="display:flex; justify-content: flex-end;">
                <v-row>
                  <v-col class="text-right">
                    <span class="font-weight-medium subtitle">Total: </span>
                    <span class="font-weight-bold title">${{ Sumacuotasapagar("totalcuota")}}</span>
                  </v-col>
                </v-row>
              </v-footer>
            </template> -->
            </v-data-table>
            <div class="footertotal">
              <h2 class="mr-2">Total a Pagar:</h2>
              <v-card dark flat outlined height="30" width="130" class="mr-2">
                <h3 style="margin-top: 2px; text-align: right" class="mr-2">
                  ${{ Sumacuotasapagar("totalcuota") }}
                </h3>
              </v-card>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="error"
              class="font-weight-bold"
              @click="DialogPagarDeudas = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              dark
              class="font-weight-bold botones_pagar"
              color="claro"
              @click="MetodosDePago = true"
            >
              Pagar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        width="350"
        v-model="MetodosDePago"
      >
        <v-card>
          <v-toolbar title="Opening from the top" dark>
            <v-toolbar-title>Seleccione el método de pago:</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-radio-group v-model="tipopago" inline>
              <v-radio
                label="Pago con tarjeta crédito/débito/qr"
                :value="1"
                color="claro"
                class="font-weight-bold"
              ></v-radio>
              <!-- <v-radio
              label="Interbanking"
              :value="2"
              color="claro"
              class="font-weight-bold"
            ></v-radio> -->
              <v-radio
                label="Imprimir recibo para pago"
                :value="3"
                color="claro"
                class="font-weight-bold"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="MetodosDePago = false" dark color="error"
              >cancelar</v-btn
            >
            <v-btn color="claro" dark @click="PagarCuotas(tipopago)"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
// import pagardeuda from "../../views/Pagar_deuda_inmueble";
export default {
  props: {
    user: Object,
  },
  data: () => ({
    dialog: false,
    headerscuotas: [
      {
        text: "AÑO",
        align: "start",
        value: "anio",
        class: "white--text",
        group: {
          open: false,
        },
      },
      {
        text: "CUOTA",
        value: "cuota",
        align: "right",
        class: "white--text",
      },
      {
        text: "TASA",
        value: "tasa",
        align: "right",
        class: "white--text",
      },
      {
        text: "OBRA",
        value: "obra",
        align: "right",
        class: "white--text",
      },
      {
        text: "VENCIMIENTO",
        value: "fecha_vencimiento",
        align: "right",
        class: "white--text",
      },
      {
        text: "ESTADO",
        value: "estadodeuda",
        align: "right",
        class: "white--text",
      },
      {
        text: "ORIGINAL",
        value: "original_lindo",
        class: "white--text",
        align: "right",
      },
      {
        text: "RECARGO",
        value: "recargos_lindo",
        class: "white--text",
        align: "right",
      },
      {
        text: "TOTAL A PAGAR",
        value: "total_lindo",
        class: "white--text",
        align: "right",
      },
    ],
    cuotas: [],
    cuotasseleccionadas: [],
    datospagardeuda: [],
    // totalapagar:"",
    CuentaMunicipal: "",
    headerscuotasapagar: [
      { text: "CUOTA", align: "start", value: "cuota" },
      { text: "TASA", value: "tasa" },
      { text: "OBRA", value: "obra" },
      { text: "VENCIMIENTO", value: "fecha_vencimiento" },
      { text: "ESTADO", value: "estado" },
      { text: "ORIGINAL", value: "original_lindo" },
      { text: "DESCUENTO", value: "descuento_lindo" },
      { text: "ACTUALIZ.", value: "actualizacion_lindo" },
      { text: "RECARGOS", value: "recargos_lindo" },
      { text: "TOTAL CUOTA", value: "total_lindo" },
    ],
    cuotasapagar: [],
    DialogDeudas: false,
    DialogPagarDeudas: false,
    tipopago: null,
    MetodosDePago: false,
    formulariopago: {},
  }),

  computed: {
    suma_deudas() {
      return `${
        this.datospagardeuda.totalCuotas +
        this.datospagardeuda.totalCuotasJuicio +
        this.datospagardeuda.totalCuotasPlan
      }`;
    },
    cunicasseleccionadas() {
      return this.cuotasseleccionadas.map((v) => {
        return v.cunica;
      });
    },
  },

  watch: {},

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.ListadoCuotas();
    },

    Estado(item) {
      return item.convenio > 0
        ? `Plan ${item.estadonew} ${item.estaDeta}`
        : item.estaDeta;
    },

    ListadoCuotas(item) {
      this.cuotas = [];
      this.cuotasseleccionadas = [];
      this.datospagardeuda = [];
      console.log("usuario en cuotasss", this.user);
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}cementerios/traerCuotas`, {
          cunica: item.cunica,
          orden: item.num_orden,
        })
        .then(function (response) {
          console.log("Listado Cuotas cementerios", response);
          obj.datospagardeuda = response.data;
          obj.cuotas = response.data.cuotas;
          obj.$nextTick(() => {
            console.log("DataTable renderizado completamente.");
            var cuotasGrupo =
              obj.$refs.tablacuotas.$el.querySelectorAll(".mdi-minus");
            console.log("cuotiii", cuotasGrupo);
            for (var i = 0; i < cuotasGrupo.length; i++) {
              cuotasGrupo[i].click();
            }
          });
          obj.dialog = true;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    AbrirCuotas() {
      if (this.cuotasseleccionadas.length != 0) {
        this.cuotasapagar = this.cuotasseleccionadas;
        console.log("Cuotas a pagar", this.cuotasapagar);
        this.DialogPagarDeudas = true;
      } else {
        this.$swal.fire({
          title: "Error",
          text: "Seleccione una cuota a pagar",
          icon: "error",
        });
        // alert("Seleccione una cuota a pagar");
      }
    },

    PagarCuotas(tipo) {
      console.log("tipo", tipo);
      console.log("cuota que van", this.cunicasseleccionadas);
      console.log("user cemen", this.user);
      var obj = this;

      if (tipo == 3) {
        console.log("entra recibo");
        this.$ajax
          .post(
            `${this.$apitramites}cementerios/imprimirRecibo`,
            {
              orden: this.user.datoscementerio.num_orden,
              cunica: this.cunicasseleccionadas,
              tipo: "",
            },
            { responseType: "blob" }
          )
          .then(function (response) {
            console.log("imprimir recibo", response);
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
            obj.vaciarExpanded();
          })
          .catch(function (error) {
            obj.snackbar = true;
            obj.msj = error;
          });
      } else if (tipo == 1) {
        console.log("entra tarjeta");
        this.$ajax
          .post(`${this.$apitramites}cementerios/pagarCuotas`, {
            orden: this.user.datoscementerio.num_orden,
            cunica: this.cunicasseleccionadas,
            tipo: "",
            pago: "MACRO",
          })
          .then(function (response) {
            console.log("pagar tarjeta", response);
            obj.AbrirPaginaPago(response.data);
            // obj.$router.push('/pagina_redireccion');
          })
          .catch(function (error) {
            obj.snackbar = true;
            obj.msj = error;
          });
      } else {
        console.log("entra interbanking");
        this.$swal.fire({
          title: "Seleccione el método de pago",
          icon: "info",
        });
        // this.$ajax
        //   .post(`${this.$apitramites}cementerios/pagarCuotas`, {
        //     orden: this.user.datoscementerio.num_orden,
        //     cunica: this.cunicasseleccionadas,
        //     tipo: "",
        //     pago: "BTOB",
        //   })
        //   .then(function (response) {
        //     console.log("imprimir Interbanking", response);

        //     let content = "";
        //     response.data.forEach(function (item) {
        //       content += `${item.respuesta[0].descripcion}<br>`;
        //       content += `Identificador de deuda: ${item.identificadorDeuda}<br><br>`;
        //     });

        //     obj.$swal.fire({
        //       title: "Resultados de Pagos",
        //       html: content,
        //       icon: "info",
        //     });
        //     obj.vaciarExpanded();
        //   })
        //   .catch(function (error) {
        //     obj.snackbar = true;
        //     obj.msj = error;
        //   });
      }
    },

    // AbrirPaginaPago(formulario){
    //   this.$router.push({
    //     path: '/Pagina_redireccion?'+new URLSearchParams(formulario).toString(), // Reemplaza con el nombre de la ruta de la página de redirección
    //     params: formulario
    //   });
    // },

    AbrirPaginaPago(formulario) {
      const url = this.$router.resolve({
        path:
          "/Pagina_redireccion?" + new URLSearchParams(formulario).toString(), // Reemplaza con el nombre de la ruta de la página de redirección
        params: formulario,
      }).href;
      window.open(url, "_blank");
      this.vaciarExpanded();
    },

    SumaPorAnio(items, valor) {
      if (valor === "total") {
        const total = items.reduce((sum, item) => sum + item.totalcuota, 0);
        return this.formatNumber(total);
      }
      if (valor === "original") {
        const original = items.reduce((sum, item) => sum + item.importe, 0);
        return this.formatNumber(original);
      }
      if (valor === "actualizado") {
        const actualizado = items.reduce((sum, item) => sum + item.recargo, 0);
        return this.formatNumber(actualizado);
      }
    },

    formatNumber(number) {
      if (number >= 1000) {
        const formatted = new Intl.NumberFormat("es-ES").format(number);
        const parts = formatted.split(",");
        if (parts.length === 2) {
          return (
            parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + parts[1]
          );
        }
        return formatted;
      } else {
        return new Intl.NumberFormat("es-ES", {
          minimumFractionDigits: 2,
        }).format(number);
      }
    },

    Sumacuotasapagar(key) {
      // sum data in give key (property)
      const total = this.cuotasseleccionadas.reduce(
        (a, b) => a + (b[key] || 0),
        0
      );
      return this.formatNumber(total);
    },

    SumaCuotasSeleccionadas(key) {
      // sum data in give key (property)
      const total = this.cuotasseleccionadas.reduce(
        (a, b) => a + (b[key] || 0),
        0
      );
      return this.formatNumber(total);
    },

    async clickaso(item) {
      console.log("item seleccionado", item);
      // Busca si la cuota ya existe en cuotasseleccionadas
      const cuotaExistente = this.cuotasseleccionadas.findIndex(
        (x) => x.cunica === item.cunica
      );

      if (cuotaExistente !== -1) {
        // Si la cuota ya existe y es específica para tasa 8 y obra 230, elimínala
        if (item.tasa == "8" && item.obra == 230) {
          await this.removeCuota(cuotaExistente);

          // Luego busca y elimina también la cuota para tasa 1 y obra 0
          const cuotaExistenteTasa1 = this.cuotasseleccionadas.findIndex(
            (x) => x.cuota == item.cuota && x.tasa == "1" && x.obra == 0
          );

          if (cuotaExistenteTasa1 !== -1) {
            await this.removeCuota(cuotaExistenteTasa1);
          }
        }
      } else {
        // Si el item tiene tasa 1 o F, sigue este proceso
        if (item.tasa == "1" || item.tasa == "F") {
          // Busca una cuota con tasa 8 y obra 230
          const buscaCuota = await this.getCuota(item.cuota, "8", 230);

          // Asegúrate de que no haya sido seleccionada previamente
          const yaSelecta = this.cuotasseleccionadas.findIndex(
            (x) => x.cuota == item.cuota && x.tasa == "8" && x.obra == 230
          );

          // Si se encuentra una cuota y no está seleccionada, agrégala
          if (buscaCuota.length > 0 && yaSelecta === -1) {
            await this.addCuota(buscaCuota[0]);
          }

          // Finalmente, agrega el item actual
          // await this.addCuota(item);
        }
      }
    },

    // Funciones auxiliares

    async removeCuota(index) {
      this.cuotasseleccionadas.splice(index, 1);
    },

    async getCuota(cuota, tasa, obra) {
      return this.cuotas.filter(
        (v) => v.cuota == cuota && v.tasa == tasa && v.obra == obra
      );
    },

    async addCuota(item) {
      this.cuotasseleccionadas.push(item);
    },

    vaciarExpanded() {
      // this.$emit("vaciarExpanded");
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.tabla {
  border: 2px solid;
  border-radius: 20px;
  border-color: #638699;
  padding: 0px;
}
</style>
