<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    class="zindex-dialog"
  >
    <v-form
      @submit.prevent="EnviarSolicitud"
      ref="formtramite"
      v-model="validform"
      class="formu"
      lazy-validation
    >
      <v-card>
        <v-app-bar color="oscuro" dark>
          <v-spacer></v-spacer>
          <v-toolbar-title class="mayuscula"
            >{{ user.disabled ? "ficha" : "editar formulario" }} del trámite
            {{ user.tramite.pktramite }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text>
          <!-- <v-container fluid> -->
          <v-row>
            <v-col cols="12" md="12">
              <v-card>
                <v-toolbar dense color="claro" dark flat>
                  <v-toolbar-title>Titular </v-toolbar-title>
                </v-toolbar>
                <card-text>
                  <v-container fluid>
                    <v-row class="mt-1">
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          label="CUIT"
                          :rules="[(v) => !!v || 'Falta el cuit']"
                          required
                          :value="
                            datostramite.padron[0].cuit
                              ? datostramite.padron[0].cuit
                              : ''
                          "
                          name="titular_cuit"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          label="NOMBRE"
                          :rules="[(v) => !!v || 'Falta el nombre']"
                          required
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].titular_nombre
                              : ''
                          "
                          name="titular_nombre"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].nom_conti
                              : ''
                          "
                          label="APELLIDO"
                          :rules="[(v) => !!v || 'Falta el apellido']"
                          required
                          name="titular_apellido"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-autocomplete
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].t_cod
                              : ''
                          "
                          :items="provincias"
                          item-text="t_deta"
                          item-value="t_cod"
                          v-model="combo_provincia_titular"
                          placeholder="Seleccione una provincia"
                          :rules="[(v) => !!v || 'Seleccione una provincia']"
                          required
                          outlined
                          filled
                          dense
                          label="PROVINCIA"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].localidad
                              : ''
                          "
                          label="LOCALIDAD"
                          :rules="[(v) => !!v || 'Falta la localidad']"
                          required
                          name="titular_localidad"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="1" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].cod_post
                              : ''
                          "
                          label="CP"
                          :rules="[(v) => !!v || 'Falta el CP']"
                          required
                          name="titular_cp"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].dp_calle
                              : ''
                          "
                          label="CALLE"
                          :rules="[(v) => !!v || 'Falta la calle']"
                          required
                          name="titular_calle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="1" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].dp_nro
                              : ''
                          "
                          label="N°"
                          :rules="[(v) => !!v || 'Falta el número']"
                          required
                          type="number"
                          name="titular_numcalle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="1" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].dp_piso
                              : ''
                          "
                          label="PISO"
                          name="titular_piso"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="1" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].dp_dpto
                              : ''
                          "
                          label="DPTO"
                          name="titular_dpto"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col md="3" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].designacion_mzna
                              : ''
                          "
                          label="MANZANA"
                          :rules="[(v) => !!v || 'Falta la Manzana']"
                          required
                          name="titulo_manzana"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="3" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].designacion_lote
                              : ''
                          "
                          label="LOTE"
                          :rules="[(v) => !!v || 'Falta el lote']"
                          required
                          name="titulo_lote"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col> -->
                    </v-row>
                  </v-container>
                </card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card min-height="240">
                <v-toolbar dense color="claro" dark flat>
                  <v-toolbar-title>Otros Titulares</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn small color="claro" @click="NuevoTitular()" dense
                  >agregar titular</v-btn
                > -->
                  <v-btn
                    color="white"
                    v-if="!user.disabled"
                    @click="NuevoTitular()"
                    dense
                  >
                    <h4 style="color: #000">agregar titular</h4>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerstitulares"
                        :items="titulares"
                        item-key="pktitular"
                        style="border: solid 1px"
                        hide-default-footer
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                style="
                                  margin-right: 10px;
                                  background-color: #4f798f;
                                  border-radius: 5px;
                                  height: 25px;
                                  width: 25px;
                                "
                                v-bind="attrs"
                                v-on="on"
                                :disabled="user.disabled"
                                dark
                                x-small
                                @click="EditarTitular(item)"
                              >
                                mdi-pencil
                              </v-icon>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                style="
                                  background-color: red;
                                  border-radius: 5px;
                                  height: 25px;
                                  width: 25px;
                                "
                                v-bind="attrs"
                                dark
                                x-small
                                :disabled="user.disabled"
                                v-on="on"
                                @click="EliminarOtroTitular(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:[`item.nombre_completo`]="{ item }">
                          {{ item.apellido }} {{ item.nombre }}
                        </template>
                        <!-- <template v-slot:no-data>
                    <v-btn color="#40A5DD" x-small dark @click="initialize">Cargar</v-btn>
                </template> -->
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card min-height="240">
                <v-toolbar dense color="claro" dark flat>
                  <v-toolbar-title>Domicilio Postal</v-toolbar-title>
                </v-toolbar>
                <card-text>
                  <v-container fluid>
                    <v-row class="mt-2">
                      <v-col md="5">
                        <v-autocomplete
                          :readonly="user.disabled"
                          :items="provincias"
                          item-text="t_deta"
                          item-value="t_cod"
                          v-model="combo_provincia_postal"
                          placeholder="Seleccione una provincia"
                          :rules="[(v) => !!v || 'Seleccione una provincia']"
                          required
                          outlined
                          filled
                          dense
                          label="PROVINCIA"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="5">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].p_localidad
                              : ''
                          "
                          label="LOCALIDAD"
                          :rules="[(v) => !!v || 'Falta la localidad']"
                          required
                          name="postal_localidad"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].p_cp
                              : ''
                          "
                          label="CP"
                          :rules="[(v) => !!v || 'Falta el CP']"
                          required
                          name="postal_cp"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          label="CALLE"
                          :rules="[(v) => !!v || 'Falta la calle']"
                          required
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].p_calle
                              : ''
                          "
                          name="postal_calle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          label="N°"
                          :rules="[(v) => !!v || 'Falta el número']"
                          required
                          name="postal_numcalle"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].p_numcalle
                              : ''
                          "
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          label="PISO"
                          name="postal_piso"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].p_piso
                              : ''
                          "
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          label="DPTO"
                          name="postal_dpto"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].p_dpto
                              : ''
                          "
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
              <v-card>
                <v-toolbar dense color="claro" dark flat>
                  <v-toolbar-title>Datos del Inmueble</v-toolbar-title>
                </v-toolbar>
                <card-text>
                  <v-container fluid>
                    <v-row class="mt-1">
                      <v-col md="3">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_localidad
                              : ''
                          "
                          label="LOCALIDAD"
                          :rules="[(v) => !!v || 'Falta la localidad']"
                          required
                          name="inmueble_localidad"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_calle
                              : ''
                          "
                          label="CALLE"
                          :rules="[(v) => !!v || 'Falta la calle']"
                          required
                          name="inmueble_calle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_numcalle
                              : ''
                          "
                          label="N°"
                          :rules="[(v) => !!v || 'Falta el número']"
                          required
                          name="inmueble_numcalle"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_piso
                              : ''
                          "
                          label="PISO"
                          name="inmueble_piso"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_dpto
                              : ''
                          "
                          label="DPTO"
                          name="inmueble_dpto"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_metrosfrente
                              : ''
                          "
                          label="MT. FRENTE"
                          :rules="[(v) => !!v || 'Faltan mt.frente']"
                          required
                          name="metros_frente"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_metroscontrafrente
                              : ''
                          "
                          label="MT. CONTRAFRENTE"
                          :rules="[(v) => !!v || 'Falta mt.contrafrente']"
                          required
                          name="metros_contrafrente"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_metrosochava
                              : ''
                          "
                          label="MT. OCHAVA"
                          :rules="[(v) => !!v || 'Falta mt.ochava']"
                          required
                          name="metros_ochava"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_metrosladoizq
                              : ''
                          "
                          label="MT. LADO IZQ."
                          :rules="[(v) => !!v || 'Faltan mt. lado izq.']"
                          required
                          name="metros_ladoizq"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_metrosladoder
                              : ''
                          "
                          label="MT. LADO DER."
                          :rules="[(v) => !!v || 'Falta mt. lado der.']"
                          required
                          name="metros_ladoder"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="4" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].di_inscdominio
                              : ''
                          "
                          label="INSC. DOMINIO"
                          :rules="[(v) => !!v || 'Falta insc. dominio']"
                          required
                          name="insc_dominio"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="12" class="margen">
                        <v-autocomplete
                          :readonly="user.disabled"
                          :items="tipos_obra"
                          item-text="t_deta"
                          item-value="t_cod"
                          v-model="combo_tipo_obra"
                          placeholder="Seleccione un tipo de obra"
                          :rules="[(v) => !!v || 'Seleccione un tipo de obra']"
                          required
                          outlined
                          filled
                          dense
                          label="TIPO DE OBRA"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="12" class="mb-4">
                        <h3 class="margen">Designación según el título</h3>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].designacion_mzna
                              : ''
                          "
                          label="MANZANA"
                          :rules="[(v) => !!v || 'Falta la Manzana']"
                          required
                          name="titulo_manzana"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col md="6" class="margen">
                        <v-text-field
                          :readonly="user.disabled"
                          :value="
                            datostramite.tramite[0]
                              ? datostramite.tramite[0].designacion_lote
                              : ''
                          "
                          label="LOTE"
                          :rules="[(v) => !!v || 'Falta el lote']"
                          required
                          name="titulo_lote"
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col
                        md="6"
                        class="margen mt-1"
                        style="background-color: #b3e5fc"
                      >
                        <span style="color: #f00">*</span>
                        <strong>Requisitos:</strong>
                        <ul>
                          <li>Certificado de dominio antecedente</li>
                          <li>Certificado de Dominio</li>
                          <li>
                            Convenio cesión de contrato / Boleto adhesión a
                            fideicomiso
                          </li>
                          <li>Copia simple de escritura traslativa</li>
                          <li>
                            Copia simple de escritura traslativa protocolizada
                            firmada por el escribano
                          </li>
                          <li>Croquis</li>
                          <li>DNI</li>
                          <li>Escritura inscripta en RPBA</li>
                          <li>Planilla de estadística de edificación</li>
                          <li>Plano antecedente</li>
                          <li>Plano para visado previo de obra</li>
                          <li>Relevamiento planialtimétrico y amojonamiento</li>
                          <li>Certificado libre deuda alimentaria</li>
                        </ul>
                      </v-col>
                      <!-- <div class="dropzone mt-2" style="position: relative;width: 100%;" @dragenter.prevent="handleDragEnter"
                @dragover.prevent="handleDragOver"
                @drop.prevent="handleDrop">
                    <div class="dropzone dropzone-previews dz-clickable" id="adjuntoCatastro" archivo="">
                        <div class="dz-message"  data-dz-message>
                            <v-file-input
                                v-model="files"
                                label="Archivos"
                                placeholder="Arrastre o haga click para agregar el archivo"
                                show-size
                                multiple
                            ></v-file-input>
                        </div>
                    </div>
                </div> -->
                      <v-col
                        cols="12"
                        md="6"
                        style="display: flex; align-items: center"
                      >
                        <v-file-input
                          chips
                          multiple
                          filled
                          show-size
                          :value="files"
                          @change="handleFileChange"
                          label="Archivo"
                          persistent-hint
                          :hint="`${files.length} archivos seleccionados`"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip close @click:close="removeFile(index)">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- </v-container> -->
        </v-card-text>
        <v-card-actions class="custom-actions">
          <div>
            <v-btn dark color="error" class="mr-2" @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn
              dark
              color="claro"
              type="submit"
              id="btnenviarsolicitud"
              v-if="!user.disabled"
            >
              CONFIRMAR
            </v-btn>
          </div>
        </v-card-actions>

        <v-dialog v-model="DialogTitular" width="700" persistent>
          <v-card>
            <v-form
              @submit.prevent="AgregarTitular"
              ref="formtitular"
              v-model="validformtitular"
              class="formu"
            >
              <v-card-title class="font-weight-bold">
                <v-row justify="center" style="margin-top: 5px">
                  {{ TituloTitular }}
                </v-row>
              </v-card-title>
              <v-card-text style="padding: 0px 20px 0px">
                <v-row class="mt-4">
                  <v-col md="4" sm="12" class="my-custom-class">
                    <v-text-field
                      label="CUIT"
                      :rules="[(v) => !!v || 'requerido']"
                      :value="
                        datos_otro_titular.cuit ? datos_otro_titular.cuit : ''
                      "
                      required
                      name="cuit"
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <v-text-field
                      label="Apellido"
                      :rules="[(v) => !!v || 'requerido']"
                      :value="
                        datos_otro_titular.apellido
                          ? datos_otro_titular.apellido
                          : ''
                      "
                      required
                      name="apellido"
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" sm="12">
                    <v-text-field
                      label="Nombre"
                      :rules="[(v) => !!v || 'requerido']"
                      :value="
                        datos_otro_titular.nombre
                          ? datos_otro_titular.nombre
                          : ''
                      "
                      required
                      name="nombre"
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -20px">
                  <v-col md="6" sm="12">
                    <v-text-field
                      label="Calle"
                      name="calle"
                      :rules="[(v) => !!v || 'requerido']"
                      :value="
                        datos_otro_titular.calle ? datos_otro_titular.calle : ''
                      "
                      required
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" sm="12">
                    <v-text-field
                      label="N°"
                      name="numcalle"
                      :value="
                        datos_otro_titular.numcalle
                          ? datos_otro_titular.numcalle
                          : ''
                      "
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" sm="12">
                    <v-text-field
                      label="Piso"
                      :value="
                        datos_otro_titular.piso ? datos_otro_titular.piso : ''
                      "
                      name="piso"
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" sm="12">
                    <v-text-field
                      label="Depto"
                      name="dpto"
                      :value="
                        datos_otro_titular.dpto ? datos_otro_titular.dpto : ''
                      "
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -20px">
                  <v-col md="5" sm="12">
                    <v-autocomplete
                      :readonly="user.disabled"
                      :items="provincias"
                      item-text="t_deta"
                      item-value="t_cod"
                      v-model="combo_provincia_nuevo_titular"
                      placeholder="Seleccione una provincia"
                      outlined
                      filled
                      dense
                      label="Provincia"
                    ></v-autocomplete>
                  </v-col>
                  <v-col md="5" sm="12">
                    <v-text-field
                      label="Localidad"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      :value="
                        datos_otro_titular.localidad
                          ? datos_otro_titular.localidad
                          : ''
                      "
                      name="localidad"
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="2" sm="12">
                    <v-text-field
                      label="CP"
                      :rules="[(v) => !!v || 'requerido']"
                      required
                      name="cp"
                      :value="
                        datos_otro_titular.cp ? datos_otro_titular.cp : ''
                      "
                      dense
                      filled
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-center mt-4">
                <v-btn dark color="error" @click="DialogTitular = false">
                  cancelar
                </v-btn>
                <v-btn dark color="claro" type="submit" id="btnagregartitular">
                  GUARDAR
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
          {{ msj }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbarconfirm = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    dialog: false,
    validform: true,
    validformtitular: true,
    snackbarconfirm: false,
    timeout: 3000,
    msj: "",
    disabled: "",
    provincias: [],
    tipos_obra: [],
    files: [],
    headerstitulares: [
      { text: "DNI/CUIT", align: "start", value: "cuit" },
      { text: "APELLIDO Y NOMBRE", value: "nombre_completo", align: "start" },
      { text: "LOCALIDAD", value: "localidad", align: "start" },
      { text: "OPCIONES", value: "actions", align: "right", sortable: false },
    ],
    titulares: [],
    DialogTitular: false,
    combo_tipo_obra: "",
    combo_provincia_postal: "",
    combo_provincia_titular: "",
    combo_provincia_nuevo_titular: "",
    datostramite: [],

    datos_otro_titular: {},
    controleditartitular: 0,
    // archivos_cargados:[]
  }),
  mounted() {
    // this.TraerTiposObras();
  },

  computed: {
    TituloTitular() {
      return this.controleditartitular == 1
        ? "Nuevo Titular"
        : "Editar Titular";
    },
  },

  watch: {},

  methods: {
    EnviarSolicitud(event) {
      const formData = new FormData(event.target);
      formData.append("pktramite", Number(this.user.tramite.pktramite));
      formData.append("tipo_obra", this.combo_tipo_obra);
      formData.append("postal_provincia", this.combo_provincia_postal);
      formData.append("titular_provincia", this.combo_provincia_titular);

      for (const archivo of this.files) {
        console.log("archivo", archivo);
        formData.append("adjunto", archivo);
      }

      const titularesJSON = JSON.stringify(this.titulares);
      formData.append("titulares", titularesJSON);

      console.log("formulario", formData);

      if (this.$refs.formtramite.validate()) {
        var obj = this;

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${this.$apitramites}obrasprivadas/enviarFormulario`,
          data: formData,
        };

        this.$ajax
          .request(config)
          .then((response) => {
            console.log("Enviar formulario", response);
            obj.$swal.fire({
              title: "¡Exito!",
              text: "Solicitud enviada con exito!!!",
              icon: "success",
            });
            obj.$refs.formtramite.reset();
            setTimeout(function () {
              // obj.$emit("vaciarExpanded");
              obj.dialog = false;
              obj.$emit("actualizarTabla");
            }, 1000);
          })
          .catch((error) => {
            obj.$swal.fire({
              title: "Error",
              text: error,
              icon: "error",
            });
          });
      }
    },

    TraerTiposObras() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/listadoTiposDeObraObrasPrivadas`)
        .then(function (response) {
          console.log("tipos de obra", response);
          obj.tipos_obra = response.data;
          obj.TraerProvincias();
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    TraerProvincias() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/listadoProvincias`)
        .then(function (response) {
          console.log("provincias", response);
          obj.provincias = response.data;
          obj.DatosFormulario();
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    DatosFormulario() {
      var obj = this;
      this.$ajax
        .get(
          `${this.$apitramites}obrasprivadas/formulario/${this.user.tramite.cuenta}/${this.user.tramite.pktramite}`
        )
        .then(function (response) {
          console.log("datos formulario", response);
          if (response.data.tramite[0]) {
            obj.combo_tipo_obra =
              response.data.tramite[0].fktipoobra.toString();
            obj.combo_provincia_postal =
              response.data.tramite[0].p_fkprovincia.toString();
            obj.combo_provincia_titular =
              response.data.tramite[0].t_cod.toString();
          }
          obj.datostramite = response.data;
          obj.titulares = response.data.tramiteTitulares;
          obj.dialog = true;
        })
        .catch(function (error) {
          obj.snackbar = true;
          obj.msj = error;
        });
    },

    NuevoTitular() {
      (this.datos_otro_titular = {}), (this.combo_provincia_nuevo_titular = "");
      this.controleditartitular = 1;
      this.DialogTitular = true;
    },

    EditarTitular(item) {
      console.log(item);
      // console.log("agregar titular", this.titulares.indexOf(item));
      this.combo_provincia_nuevo_titular = item.provincia.toString();
      this.controleditartitular = 0;
      this.datos_otro_titular = Object.assign({}, item);
      // this.datos_otro_titular.provincia = item.provincia.toString();
      this.DialogTitular = true;
      // this.$swal.fire({
      //   title: "Opción en mantenimiento",
      //   icon: "info"
      // });
    },

    AgregarTitular(event) {
      // console.log("agregar titular", this.titulares.indexOf(item));
      if (this.$refs.formtitular.validate()) {
        const formData = new FormData(event.target);
        formData.append("provincia", this.combo_provincia_nuevo_titular);
        const nuevoTitular = Object.fromEntries(formData);
        if (this.controleditartitular == 1) {
          this.titulares.push(nuevoTitular);
        } else {
          Object.assign(
            this.titulares[this.controleditartitular],
            nuevoTitular
          );
        }
        console.log("nuevo titular añadido:", nuevoTitular);
        this.DialogTitular = false;
        this.$refs.formtitular.reset();
      }
    },

    EliminarOtroTitular(item) {
      const Index = this.titulares.indexOf(item);
      console.log(item);
      // var obj = this;
      this.$swal
        .fire({
          title: "¿Desea eliminar este titular?",
          text: "¡Esta acción no se puede revertir!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No, cancelar!",
          confirmButtonText: "Sí, eliminar!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.titulares.splice(Index, 1);
          }
        });
    },

    // handleFileUpload(event) {
    //     // Manejar la lógica de carga de archivos aquí si es necesario
    //     this.archivos_cargados = event.target.files;
    // },
    handleDragEnter(event) {
      // Prevenir comportamientos predeterminados y agregar clases o estilos de realce si lo deseas
      event.preventDefault();
    },
    handleDragOver(event) {
      // Prevenir comportamientos predeterminados y agregar clases o estilos de realce si lo deseas
      event.preventDefault();
    },
    handleDrop(event) {
      // Prevenir comportamientos predeterminados y manejar la caída de archivos
      event.preventDefault();

      // Acceder a la lista de archivos desde el evento
      const droppedFiles = event.dataTransfer.files;

      // Puedes hacer algo con los archivos aquí, por ejemplo, almacenarlos en la variable files
      this.files = Array.from(droppedFiles);
    },

    vaciarExpanded() {
      // this.$emit("vaciarExpanded");
      this.dialog = false;
    },

    handleFileChange(newFiles) {
      if (!newFiles) {
        return;
      }

      if (!Array.isArray(newFiles)) {
        newFiles = [newFiles];
      }

      if (this.files && this.files.length > 0) {
        const uniqueNewFiles = newFiles.filter(
          (newFile) =>
            !this.files.some(
              (existingFile) =>
                existingFile.name === newFile.name &&
                existingFile.size === newFile.size
            )
        );

        this.files = [...this.files, ...uniqueNewFiles];
      } else {
        this.files = newFiles;
      }
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.label-text {
  margin-bottom: 4px;
  /* font-weight: bold; */
  font-size: 15px;
  opacity: 0.85;
}
.theme--light.v-text-field--solo >>> .v-input__control > .v-input__slot {
  background-color: #afaaaa;
}
.theme--dark.v-text-field--solo >>> .v-input__control > .v-input__slot {
  background-color: #636363;
}
.margen {
  margin-top: -25px;
}
.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;
}
.dropzone .dz-message {
  text-align: center;
  margin: 3em 0;
}
/* .formu{
    background: cornsilk;
} */
</style>
